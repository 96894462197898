// imports base scripts as one file
import * as constants from "./constants.js";

// imports base scripts as one file
import { translations as translations_ru } from "./translations/translations.ru.js";
import { translations as translations_en } from "./translations/translations.en.js";

// ru - for Rukme, en for LetsBeFunny
export const SITE_LANGUAGE = constants.LANGUAGE.ENGLISH;

// Google Analytics id
export const GA_ID =
    SITE_LANGUAGE === constants.LANGUAGE.RUSSIAN
        ? "G-XSC1446KTB"
        : "G-FHNHFC00NE";

const translations =
    SITE_LANGUAGE === constants.LANGUAGE.RUSSIAN
        ? translations_ru
        : translations_en;
const site =
    SITE_LANGUAGE === constants.LANGUAGE.RUSSIAN
        ? constants.SITES.RUKME
        : constants.SITES.LBF;
const api = `${site.protocol}://${site.host}:${site.port}/api`;
const host = `${site.protocol}://${site.host}`;

export const SITE_URL =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : host;

export const SERVICE_URL =
    process.env.NODE_ENV === "development" ? "http://localhost:8800/api" : api;

export { translations, constants };
